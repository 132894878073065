import { useState } from "react";
import Loading from "./Loading";
import { Row, Table, Form, Input, Button, Col, Label } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";

const { apiOrigin } = getConfig();

const Flags = ({ value, mask, onSwitch, disabled }) => {

    function handleChange(e) {
        onSwitch(mask, e.target.checked);
    }

    return (
        <div className="form-check form-switch">
            <Input disabled={disabled} checked={(value & mask) !== 0} type="switch" role="switch" onChange={handleChange} />
        </div>
    );
}

const Robot = ({ robot, user }) => {
    const [allowed, setAllowed] = useState(robot.allow !== 0);
    const { getAccessTokenSilently } = useAuth0();

    async function handleSwitch(mask, state) {
        try {
            const url = `api/robots/${robot.id}/user/${user}/${state}`;
            const token = await getAccessTokenSilently();
            const headers = {
                Authorization: `Bearer ${token}`, 'Content-Type': 'application/json',
            }

            const response = await fetch(`${apiOrigin}/${url}`, {
                method: 'post',
                headers: headers,
            });

            if (!response.ok) {
                throw new Error('Error robot permissions update request');
            }

            const data = await response.json();
            if (data.status !== 'success') {
                throw new Error(`error ${data.code}: ${data.message}`);

            }
            setAllowed(state);

        } catch (error) {
            alert(error);
        }
    }
    return (
        <tr>
            <td>{robot.id}</td>
            <td>{robot.name}</td>
            <td>{robot.symbol}</td>
            <td>{robot.period}</td>
            <td><Flags value={allowed} mask={0x1} onSwitch={handleSwitch} /></td>
        </tr>
    );
}

const User = ({ user }) => {
    const [permissions, setPermissions] = useState(user.permissions);
    const [expand, setExpand] = useState(false);
    const [robots, setRobots] = useState([]);
    const { getAccessTokenSilently, user: Me } = useAuth0();

    async function handleSwitch(mask, state) {
        if (Me.sub === user.id && mask === 0x4 && !state) return;

        const newval = state ? permissions | mask : permissions & ~mask;
        try {
            const url = `api/users/${user.id}`;
            const token = await getAccessTokenSilently();
            const headers = {
                Authorization: `Bearer ${token}`, 'Content-Type': 'application/json',
            }

            const response = await fetch(`${apiOrigin}/${url}`, {
                method: 'post',
                headers: headers,
                body: JSON.stringify({ permissions: newval })
            });

            if (!response.ok) {
                throw new Error('Error user permissions update request');
            }

            const data = await response.json();
            if (data.status !== 'success') {
                throw new Error(`error ${data.code}: ${data.message}`);

            }
            setPermissions(newval);

        } catch (error) {
            alert(error);
        }
    }

    function handleExpand() {

        const fetchRobots = async () => {
            const token = await getAccessTokenSilently();
            try {
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
                const response = await fetch(`${apiOrigin}/api/robots/byuser/${user.id}`, {
                    headers: headers,
                });

                if (!response.ok) {
                    throw new Error('Error fetching server list');
                }

                const data = await response.json();
                if (data.status === 'success') {
                    setRobots(data.data);
                } else {
                    throw new Error(`error ${data.code}: ${data.message}`);
                }

            } catch (error) {
                console.error('Error fetching server list:', error);
                setRobots([]);
            }
        };
        if (!expand) fetchRobots(); else setRobots([]);
        setExpand(!expand);
    }

    return (
        <>
            <tr>
                <td>{user.email}</td>
                <td>{user.id}</td>
                <td><Flags value={permissions} mask={0b0001} onSwitch={handleSwitch} /></td>
                <td><Flags value={permissions} mask={0b0010} onSwitch={handleSwitch} /></td>
                <td><Flags value={permissions} mask={0b0100} onSwitch={handleSwitch} disabled={Me.sub === user.id} /></td>
                <td><Button outline size="sm" active={expand} onClick={handleExpand}>Robots</Button></td>
            </tr>
            {expand && <tr><td></td><td colSpan="5">
                <Table size="sm" striped borderless responsive>
                    <tbody>
                        {robots.map(robot => (
                            <Robot key={robot.id} robot={robot} user={user.id} />
                        ))}
                    </tbody>
                </Table>
            </td></tr>}
        </>
    );
}

const LookFor = ({ onChanged }) => {
    return (
        <Form className="pb-3" style={{ width: "100%" }} onSubmit={onChanged}>
            <Row className="row-cols-lg-auto g-1 align-items-end">
                <Col>
                    <Label
                        className="visually-hidden"
                        for="finder"
                    >
                        Search by Email:
                    </Label>
                    <Input
                        id="finder"
                        name="finder"
                        placeholder="users search"
                        type="text"
                    />
                </Col>
                <Col>
                    <Button>
                        Search
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

const Users = () => {
    document.title = "Users Management"
    const [users, setUsers] = useState([]);
    const { getAccessTokenSilently } = useAuth0();

    const doSearch = async (e) => {
        e.preventDefault();
        const what = e.target.finder.value;

        try {
            const url = `api/users?search=${what}`;
            const token = await getAccessTokenSilently();
            const headers = {
                Authorization: `Bearer ${token}`, 'Content-Type': 'application/json',
            }

            const response = await fetch(`${apiOrigin}/${url}`, {
                headers: headers,
            });

            if (!response.ok) {
                throw new Error('Error user search request');
            }

            const data = await response.json();
            if (data.status !== 'success') {
                throw new Error(`error ${data.code}: ${data.message}`);

            } else {
                setUsers(data.data);
            }

        } catch (error) {
            setUsers([]);
            alert(error);
        }
    }

    return (
        <div className="container-fluid">
            <h1 className="py-4">Users Permissions</h1>
            <Row>
                <LookFor onChanged={doSearch} />
            </Row>
            {users.length > 0 && <Table>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>User Id</th>
                        <th>Enabled</th>
                        <th>Ctrl Instances</th>
                        <th>Administrator</th>
                        <th>Permissions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <User key={user.id} user={user} />
                    ))}
                </tbody>
            </Table>
            }
        </div >
    )
};

export default withAuthenticationRequired(Users, {
    onRedirecting: () => <Loading />,
});
