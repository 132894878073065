import React, { createContext, useState } from 'react';

export const 
    USER_PERMISSIONS_ENABLED    = 0x1,
    USER_PERMISSIONS_INSTANCE   = 0x2,
    USER_PERMISSIONS_ADMIN      = 0x4;

// Создаем контекст
export const UserRolesContext = createContext();

// Создаем провайдер контекста
export const UserRolesProvider = ({ children }) => {
    const [userRoles, setUserRoles] = useState({ translate: (key, def) => {return def} });

    return (
        <UserRolesContext.Provider value={{ userRoles, setUserRoles }}>
            {children}
        </UserRolesContext.Provider>
    );
};