import React from "react";
import Logo from "./Logo";
import Lexema from "./Lexema";

const Hero = () => (
    <div className="text-center hero">
      <Logo className="main-logo my-5" />
      <h1 className="mb-4">MT on AWS Project</h1>

      <p className="mb-5">
        <Lexema lexkey="subhero" def="This is an application that controls AWS EC2 instances with MT4/5 Advisers" />        
      </p>
    </div>
  );

  export default Hero;
