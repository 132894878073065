import React, { useState, useContext } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "./Logo";
import Lexema from "./Lexema";
import LanguageSelector from "./Languages";
import { UserRolesContext, USER_PERMISSIONS_ADMIN } from "../utils/UserRolesContext";

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

const AdminActions = () => {
  //const { userRoles } = useContext(UserRolesContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <NavItem>
      <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
        <DropdownToggle className="nav-link nav-dropdown" caret>
          <Lexema lexkey="administrator">Administrator</Lexema>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => document.location.href = "/admin/robot/create"}>
            <Lexema lexkey="new_robot">New Robot...</Lexema>
          </DropdownItem>
          <DropdownItem onClick={() => document.location.href = "/admin/users"}>
            <Lexema lexkey="user_management">User Management</Lexema>
          </DropdownItem>
          <DropdownItem onClick={() => document.location.href = "/admin/partners"}>
            <Lexema lexkey="partners">Partners</Lexema>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </NavItem>
  );
};

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    user,
    isAuthenticated,
    logout,
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);
  const { userRoles } = useContext(UserRolesContext);

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });

  /*  const onLoginClick = () => {
      const cfg = { ui_locales: getCookie('lang') ?? 'en' };
      return (loginWithRedirect(cfg));
    };
  */
  return (
    <Container fluid className="bg-light shadow-sm">
      <Navbar color="light" light expand="md" className="shadow-none container">
        <NavbarBrand><Logo className="top-logo" /></NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink
                tag={RouterNavLink}
                to="/"
                exact
                activeClassName="router-link-exact-active"
              >
                <Lexema lexkey="home" def="Home" />
              </NavLink>
            </NavItem>
            {isAuthenticated && (
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/instances"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  <Lexema lexkey="instances" def="My workers" />
                </NavLink>
              </NavItem>
            )}
            {isAuthenticated && (userRoles.permissions & USER_PERMISSIONS_ADMIN) !== 0 &&
              (
                <AdminActions />
              )}
          </Nav>
          <Nav className="d-none d-md-flex align-items-center ms-auto" navbar>
            <LanguageSelector />
            {isAuthenticated && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav id="profileDropDown">
                  <img
                    src={user.picture}
                    alt="Profile"
                    className="nav-user-profile rounded-circle"
                    width="50"
                  />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem header>{user.name}</DropdownItem>
                  <DropdownItem
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    <FontAwesomeIcon icon="power-off" className="me-3" />
                    <Lexema lexkey="logout" def="Log out" />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </Nav>
          {isAuthenticated && (
            <Nav
              className="d-md-none "
              navbar
            >
              <NavItem>
                <NavLink
                  to="#"
                  id="qsLogoutBtn"
                  exact
                  tag={RouterNavLink}
                  activeClassName="router-link-exact-active"
                  onClick={() => logoutWithRedirect()}
                >
                  <Lexema lexkey="logout" def="Log out" />
                </NavLink>
              </NavItem>
            </Nav>
          )}
          <Nav
            className="d-md-none"
            navbar
          >
            <LanguageSelector />
          </Nav>
        </Collapse>
      </Navbar></Container>
  );
};

export default NavBar;
