import { useContext } from "react";
import { UserRolesContext } from "../utils/UserRolesContext";

const Lexema = ({ lexkey, def, children, ...props }) => {
    const { userRoles } = useContext(UserRolesContext);
    let res = userRoles.translate(lexkey, def, props);
//    console.log(children, res);

    if (!res && children) return (
        <span>
            {children}
        </span>
    );

    if (props.forcehtml)
        return <span dangerouslySetInnerHTML={{ __html: res }}></span>
    else
        return res;
};

export default Lexema;