import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import Lexema from './Lexema';

const BrokerSelect = ({ selectedBroker, onSelect }) => {
    const [brokers, setBrokers] = useState([]);

    const { getAccessTokenSilently } = useAuth0();
    //const { apiOrigin } = getConfig();

    useEffect(() => {
        const fetchBrokers = async () => {
            try {
                const token = await getAccessTokenSilently();
                // асинхронный запрос к API для получения списка брокеров
                const response = await fetch(`${process.env.REACT_APP_API_ORIGIN}/api/brokers`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Error fetching broker list');
                }

                const data = await response.json();

                if (data.status === 'success' && Array.isArray(data.data)) {
                    setBrokers(data.data);
                } else {
                    throw new Error('Bad data while get brokers');
                }

            } catch (error) {
                console.error('Error fetching broker list:', error);
            }
        };

        fetchBrokers();
    }, [getAccessTokenSilently]);

    return (
        <FormGroup>
            <Label for="broker"><Lexema lexkey="sel_broker" def="Select your broker"/>:</Label>
            <Input type="select" name="broker" id="broker" onChange={onSelect} value={selectedBroker}>
                <option value="0">{Lexema({lexkey: 'sel_broker', def: 'Select a broker'})}</option>
                {brokers.map((broker) => (
                    <option key={broker.id} value={broker.id}>
                        {broker.name}
                    </option>
                ))}
            </Input>
        </FormGroup>
    );
};

const ServerSelect = ({ selectedServer, brokerId, robotId, onSelect }) => {
    const [serverList, setServerList] = useState([]);

    const { getAccessTokenSilently } = useAuth0();
    //const { apiOrigin } = getConfig();

    useEffect(() => {
        if (brokerId <= 0) {
            setServerList([]);
            return;
        }
        const fetchServers = async () => {
            try {
                const token = await getAccessTokenSilently();
                if (brokerId) {
                    const response = await fetch(`${process.env.REACT_APP_API_ORIGIN}/api/servers?broker_id=${brokerId}&robot_id=${robotId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!response.ok) {
                        throw new Error('Error fetching server list');
                    }

                    const data = await response.json();

                    if (data.status === 'success' && Array.isArray(data.data)) {
                        setServerList(data.data);
                    } else {
                        throw new Error('Bad data while get servers');
                    }
                } else {
                    setServerList([]);
                }
            } catch (error) {
                console.error('Error fetching server list:', error);
            }
        };

        fetchServers();
    }, [robotId, brokerId, selectedServer, getAccessTokenSilently]);

    return (
        <FormGroup>
            <Label for="server"><Lexema lexkey="sel_server" def="Select trade server"/>:</Label>
            <Input type="select" name="server" id="server" onChange={onSelect} value={selectedServer}>
                <option value="0">{Lexema({lexkey: 'sel_server', def: 'Select a server'})}</option>
                {serverList.map((server) => (
                    <option key={server.id} value={server.id}>
                        {server.name}
                    </option>
                ))}
            </Input>
        </FormGroup>
    );
};

const TextInput = ({ label, name, value, placeholder, onChange }) => {
    return (
        <FormGroup>
            <Label for={name}>{label}</Label>
            <Input type="text" name={name} id={name} value={value} placeholder={placeholder} onChange={onChange} />
        </FormGroup>
    );
};

const PasswordInput = ({ label, name, value, onChange }) => {
    return (
        <FormGroup>
            <Label for={name}>{label}</Label>
            <Input type="password" name={name} id={name} value={value} onChange={onChange} />
        </FormGroup>
    );
};

const LaunchForm = ({ isOpen, toggle, onLogin, onSymbol, robotId }) => {
    const [selectedBroker, setSelectedBroker] = useState(0);
    const [selectedServer, setSelectedServer] = useState(0);
    const [symbol, setSymbol] = useState(onSymbol);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [validated, setValidated] = useState(false);


    const handleBrokerSelect = (e) => {
        setSelectedBroker(e.target.value);
        setSelectedServer(0);
    };

    const handleServerSelect = (e) => {
        setSelectedServer(e.target.value);
    };

    const handleSymbolChange = (e) => {
        setSymbol(e.target.value);
    };

    const handleLoginChange = (e) => {
        var l = Number(e.target.value);
        if (isNaN(l) || l <= 0) l = '';
        setLogin(l);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    useEffect(() => {
        setValidated(Number.isInteger(login) && selectedServer && password);
    }, [login, password, selectedServer]);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        // Проверяем, что все необходимые поля заполнены
        if (validated) {
            // Вызываем функцию обратного вызова onLogin и передаем ей введенные данные
            onLogin(selectedServer, symbol, login, password);

            // После обработки данных, можно закрыть модальную форму
            toggle();
        } else {
            // Обработка случая, когда не все поля заполнены
            alert('Please fill in all fields');                    
        };
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}><Lexema lexkey="runit_dialog_caption">Run robot on your account</Lexema></ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <BrokerSelect selectedBroker={selectedBroker} onSelect={handleBrokerSelect} />
                    <ServerSelect selectedServer={selectedServer} required brokerId={selectedBroker} robotId={robotId} onSelect={handleServerSelect} />
                    <FormGroup>
                        <Label for="login"><Lexema lexkey="mtlogin" def="MT Login"/>:</Label>
                        <Input min="1" step="1" required type="number" name="login" value={login} onChange={handleLoginChange} />
                    </FormGroup>
                    <PasswordInput required label={Lexema({lexkey: 'trader_passwd', def: 'Trade password'}) + ':'} name="password" value={password} onChange={handlePasswordChange} />
                    <FormGroup className="mt-4">
                        <TextInput inputmode="" label={Lexema({lexkey: 'symbol', def: 'Symbol'}) + ':'} name="symbol" placeholder={Lexema({lexkey: 'symb_alias', def: "Your broker' trade server symbol alias"})} value={symbol} onChange={handleSymbolChange} />
                        <FormText className='symbalias_attention'><Lexema lexkey="symbalias_attention" forcehtml def='<red>Please carefully check</red> that the symbol mentioned here matches the same one in your trading terminal for your trading account, as the broker may use aliases of trading instruments for different trading conditions.'/></FormText>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button disabled={!validated} color="primary" onClick={handleSubmit}><Lexema lexkey="runit_exact" def="Run It"/></Button>
                <Button color="secondary" onClick={toggle}><Lexema lexkey="cancel" def="Cancel"/></Button>
            </ModalFooter>
        </Modal>
    );
};

export default LaunchForm;
