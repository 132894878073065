import React, { useContext } from "react";
import { UserRolesContext } from "../utils/UserRolesContext";

const Logo = (props) => {
  const { userRoles } = useContext(UserRolesContext);
  const brand = userRoles.partner_logo ?? '/assets/logo/infoidea.svg';
  return (
    <img {...props} src={brand} alt="Main logo" />
  );
};

export default Logo;
