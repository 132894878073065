import { useState, useEffect } from "react";
import Loading from "./Loading";
import { Table, Input, Button } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";

const { apiOrigin } = getConfig();

const Flags = ({ value, mask, onSwitch, disabled }) => {

    function handleChange(e) {
        onSwitch(mask, e.target.checked);
    }

    return (
        <div className="form-check form-switch">
            <Input disabled={disabled} checked={(value & mask) !== 0} type="switch" role="switch" onChange={handleChange} />
        </div>
    );
}

const Broker = ({ broker, partner }) => {
    const [allowed, setAllowed] = useState(broker.allow !== 0);
    const { getAccessTokenSilently } = useAuth0();

    async function handleSwitch(mask, state) {
        try {
            const url = `api/brokers/${broker.id}/partner/${partner}/${state}`;
            const token = await getAccessTokenSilently();
            const headers = {
                Authorization: `Bearer ${token}`, 'Content-Type': 'application/json',
            }

            const response = await fetch(`${apiOrigin}/${url}`, {
                method: 'post',
                headers: headers,
            });

            if (!response.ok) {
                throw new Error('Error broker on partner permissions update request');
            }

            const data = await response.json();
            if (data.status !== 'success') {
                throw new Error(`error ${data.code}: ${data.message}`);

            }
            setAllowed(state);

        } catch (error) {
            alert(error);
        }
    }

    return (
        <tr>
            <td>{broker.id}</td>
            <td>{broker.name}</td>
            <td>{broker.legal}</td>
            <td>{broker.description}</td>
            <td><Flags value={allowed} mask={0x1} onSwitch={handleSwitch} /></td>
        </tr>
    );
}

const Robot = ({ robot, partner }) => {
    const [allowed, setAllowed] = useState(robot.allow !== 0);
    const { getAccessTokenSilently } = useAuth0();

    async function handleSwitch(mask, state) {
        try {
            const url = `api/robots/${robot.id}/partner/${partner}/${state}`;
            const token = await getAccessTokenSilently();
            const headers = {
                Authorization: `Bearer ${token}`, 'Content-Type': 'application/json',
            }

            const response = await fetch(`${apiOrigin}/${url}`, {
                method: 'post',
                headers: headers,
            });

            if (!response.ok) {
                throw new Error('Error robot on partner permissions update request');
            }

            const data = await response.json();
            if (data.status !== 'success') {
                throw new Error(`error ${data.code}: ${data.message}`);

            }
            setAllowed(state);

        } catch (error) {
            alert(error);
        }
    }
    return (
        <tr>
            <td>{robot.id}</td>
            <td>{robot.name}</td>
            <td>{robot.symbol}</td>
            <td>{robot.period}</td>
            <td><Flags value={allowed} mask={0x1} onSwitch={handleSwitch} /></td>
        </tr>
    );
}

const Partner = ({ partner }) => {
    //const [permissions, setPermissions] = useState(user.permissions);
    const [expand, setExpand] = useState(0);
    const [robots, setRobots] = useState([]);
    const [brokers, setBrokers] = useState([]);
    const { getAccessTokenSilently } = useAuth0();

    function handleExpand(e) {

        const fetchData = async (what) => {
            const token = await getAccessTokenSilently();
            const is_robots =  what === 'robots';
            try {
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
                const response = await fetch(`${apiOrigin}/api/${what}/bypartner/${partner.id}`, {
                    headers: headers,
                });

                if (!response.ok) {
                    throw new Error('Error fetching data');
                }

                const data = await response.json();
                if (data.status === 'success') {
                    if (is_robots) setRobots(data.data); else setBrokers(data.data);
                } else {
                    throw new Error(`error ${data.code}: ${data.message}`);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
                if (is_robots) setRobots([]); else setBrokers([]);
            }
        };

        let mask = e.target.dataset.mask;
        if ((expand & mask) !== 0) mask = 0;
        if ((mask & 1) !== 0) fetchData('brokers');  else setBrokers([]);
        if ((mask & 2) !== 0) fetchData('robots'); else setRobots([]);
        setExpand(mask);
    }

    return (
        <>
            <tr>
                <td>{partner.id}</td>
                <td>{partner.name}</td>
                <td>{partner.description}</td>
                <td>{partner.partner_uri}</td>
                <td><a href={partner.logo_uri} target="_blank" rel="noreferrer">{partner.logo_uri}</a></td>
                <td><Button outline size="sm" data-mask={1} active={(expand & 1) !== 0} onClick={handleExpand}>Brokers</Button></td>
                <td><Button outline size="sm" data-mask={2} active={(expand & 2) !== 0} onClick={handleExpand}>Robots</Button></td>
            </tr>
            {(expand & 1) !== 0 && <tr><td></td><td colSpan="6">
                <Table size="sm" striped borderless responsive>
                    <tbody>
                        {brokers.map(broker => (
                            <Broker key={broker.id} broker={broker} partner={partner.id} />
                        ))}
                    </tbody>
                </Table>
            </td></tr>}
            {(expand & 2) !== 0 && <tr><td></td><td colSpan="6">
                <Table size="sm" striped borderless responsive>
                    <tbody>
                        {robots.map(robot => (
                            <Robot key={robot.id} robot={robot} partner={partner.id} />
                        ))}
                    </tbody>
                </Table>
            </td></tr>}
        </>
    );
}

const Partners = () => {
    document.title = "Partners"
    const [partners, setPartners] = useState([]);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const getPartners = async () => {

            try {
                const url = `api/partners`;
                const token = await getAccessTokenSilently();
                const headers = {
                    Authorization: `Bearer ${token}`, 'Content-Type': 'application/json',
                }

                const response = await fetch(`${apiOrigin}/${url}`, {
                    headers: headers,
                });

                if (!response.ok) {
                    throw new Error('Error partners request');
                }

                const data = await response.json();
                if (data.status !== 'success') {
                    throw new Error(`error ${data.code}: ${data.message}`);

                } else {
                    setPartners(data.data);
                }

            } catch (error) {
                setPartners([]);
                alert(error);
            }
        }

        getPartners();
    }, [getAccessTokenSilently]);

    return (
        <div className="container-fluid">
            <h1 className="py-4">Partners</h1>
            {partners.length > 0 ? <Table>
                <thead>
                    <tr>
                        <th>Partner Id</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Address</th>
                        <th>Logo</th>
                        <th colSpan={2}>Permissions</th>
                    </tr>
                </thead>
                <tbody>
                    {partners.map(partner => (
                        <Partner key={partner.id} partner={partner} />
                    ))}
                </tbody>
            </Table> : <p>There are no any records yet</p>
            }
        </div >
    )
};

export default withAuthenticationRequired(Partners, {
    onRedirecting: () => <Loading />,
});
