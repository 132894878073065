import { useParams } from "react-router-dom";
import { Container, Label, FormGroup, Form, Input, FormText, Row, Col, Button, Alert } from "reactstrap";
import { useEffect, useState } from "react";
import Flags from "./Flags";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "./Loading";
import { filterFormData } from "../utils/FormData";
import Lexema from "./Lexema";

const { apiOrigin } = getConfig();

const InputField = ({ type = 'text', label, text, ...others }) => {
    const id = `${type}-${Date.now()}`;
    return (
        <FormGroup>
            <Label for={id}>
                {label}
            </Label>
            <Input id={id} type={type} {...others} />
            {text && (
                <FormText>
                    {text}
                </FormText>
            )}
        </FormGroup>
    )
}

const PlatformSelect = ({ robot_id, platform_id }) => {
    if (robot_id && !platform_id) return null;
    return (
        <FormGroup>
            <Label for="PlatformSelect">
                Trade platform:
            </Label>
            <Input id="PlatformSelect" type="select" name="platform_id" defaultValue={platform_id} required>
                <option></option>
                <option value="1">MT4</option>
                <option value="2">MT5</option>
            </Input>
        </FormGroup>
    );
}

const PeriodSelect = ({ robot_id, period }) => {
    const periods = ['', 'M1', 'M5', 'M15', 'M30', 'H1', 'H4', 'D1', 'W1'];
    if (robot_id && !period) return null;
    return (
        <FormGroup>
            <Label for="PeriodSelect">
                Period:
            </Label>
            <Input id="PeriodSelect" type="select" name="period" defaultValue={period} required >
                {periods.map((period, idx) => (
                    <option key={idx}>{period}</option>
                ))}
            </Input>
        </FormGroup>
    );
}

const ColorPicker = ({ color, ...others }) => {
    const props = color ? { defaultValue: color } : {}
    return (<InputField {...props} {...others} />);
}

const RobotEdit = () => {
    const no_logo = '/assets/no-robot-logo.png';
    const [changes, setChanges] = useState(new Set());
    const { robotId = null } = useParams();
    // eslint-disable-next-line
    const [robot_id, setRobotId] = useState(robotId);

    const [flags, setFlags] = useState(0);
    const [robot, setRobot] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [logo, setLogo] = useState(null);

    const [error, setError] = useState(null);

    const Title = robot_id === null ? 'New Robot' : 'Edit Robot';
    document.title = Title;

    const { getAccessTokenSilently } = useAuth0();

    function setRobotWrap(Robot) {
        // пустое лого заменить на картинку "нет лого"
        if (!Robot.logo_uri) Robot.logo_uri = no_logo;
        if (!Robot.color) Robot.color = '#fff';
        setLogo(Robot.logo_uri);
        // поля null заменить на ''
        Object.keys(Robot).forEach(key => { if (Robot[key] === null) Robot[key] = '' });
        // если есть многострочный description, то поправить в нем EOL на Unix-style
        if (typeof Robot.description === 'string')
            Robot.description = Robot.description.replaceAll(`\r\n`, `\n`);
        // поле flags контролируется отдельно
        setFlags(Robot.flags);
        setRobot(Robot);
        setChanges(new Set());
    }

    function handleSubmit(e) {

        const fetchPost = async (data) => {
            const token = await getAccessTokenSilently();
            try {
                const response = await fetch(`${apiOrigin}/api/robots` + (robot_id ? `/${robot_id}` : ''), {
                    method: 'post',
                    headers: { Authorization: `Bearer ${token}` },
                    body: data
                });

                if (!response.ok) {
                    throw new Error('Error posting robot');
                }

                const res = await response.json();
                if (res.status === 'success') {
                    console.log(res)

                    if (robot_id) {
                        const newRobot = robot;
                        for (const pair of data.entries()) {
                            newRobot[pair[0]] = pair[1];
                        }
                        setRobotWrap(newRobot);
                    } else {
                        document.location.href = '/admin/robot/edit/' + res.robot_id;
                        //setRobotId(res.robot_id)
                    }
                } else {
                    throw new Error(`error ${res.code}: ${res.message}`);
                }
            } catch (error) {
                console.error('Error posting robot:', error);
                setError(error.message);
            }
        };

        e.preventDefault();

        setLoaded(false);
        try {
            console.log(changes);
            const data = filterFormData(new FormData(e.target), changes);
            if (Array.from(data.keys()).length > 0) fetchPost(data); else {
                alert('nothing todo');
                return false;
            }
        } finally {
            setLoaded(true);
        }
    }

    useEffect(() => {

        if (!robot_id) {
            setRobotWrap([]);
            return;
        }
        //
        const fetchDetails = async () => {
            setLoaded(false);
            const token = await getAccessTokenSilently();
            try {
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                };

                const response = await fetch(`${apiOrigin}/api/robots/${robot_id}`, {
                    headers: headers,
                });

                if (!response.ok) {
                    throw new Error('Error fetching robot');
                }

                const data = await response.json();
                if (data.status === 'success') {
                    setRobotWrap(data.data);
                } else {
                    throw new Error(`${data.code}: ${data.message}`);
                }
            } catch (error) {
                console.error('Error fetching robot:', error);
            }
            setLoaded(true);
        };

        setLoaded(false);
        try {
            fetchDetails();
        } finally {
            setLoaded(true);
        }

    }, [robot_id, getAccessTokenSilently]);

    function handleEnabled(mask, state) {
        const newflags = state ? flags | mask : flags & ~mask;
        setFlags(newflags);
        handleFormChanged({ target: { name: "flags", value: newflags } });
    }

    function handleFormChanged(e) {
        setError(null);

        const { name, value, type } = e.target;
        if (!name) return;
        if (name === 'settings') document.getElementById('expertfile').required = value;
        // eslint-disable-next-line
        if ((type === 'file' && value) || value != robot[name]) {
            setChanges(prev => new Set(prev).add(name));
        }
        else {
            setChanges(prev => {
                const newset = new Set(prev);
                newset.delete(name);
                return newset
            });
        }
    }

    if (!loaded) return (<Loading fullscreen={false} />);
    return (
        <Container>
            <h1>{Title}{robot_id && (<> : <code>{robot_id}</code></>)}</h1>
            <Form onSubmit={handleSubmit} onChange={handleFormChanged} encType="multipart/form-data">
                <Row>
                    <Col md={2} className="mb-4">
                        <img
                            src={logo}
                            alt=""
                            className="img-fluid profile-picture mb-3 mb-md-0"
                        />
                    </Col>
                    <Col md={7}>
                        <InputField type="text" label="Name (alias)" size="32" name="name" defaultValue={robot.name} required />
                        <InputField type="file" label="Icon" required={robot_id === null} text={robot.logo_uri ?? "Upload robot logo file. Vector format (svg) is preferable"} name="logo" onChange={(e) =>
                            setLogo(URL.createObjectURL(e.target.files[0]))
                        } />
                    </Col>
                    <Col>
                        <InputField type="text" label="Tags" size="16" name="tag" defaultValue={robot.tag} />
                        <ColorPicker label="Color" type="color" name="color" className="form-control-color" color={robot.color} />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}><PlatformSelect robot_id={robot_id} platform_id={robot.platform_id} /></Col>
                    <Col md={4}><InputField type="text" label="Symbol" size="16" name="symbol" defaultValue={robot.symbol} required /></Col>
                    <Col><PeriodSelect robot_id={robot_id} period={robot.period} /></Col>
                </Row>
                <InputField type="text" label="Introduction text" size="128" name="intro" defaultValue={robot.intro} required />
                <InputField type="textarea" label="Description" text="HTML allowed" name="description" defaultValue={robot.description} />
                <Row>
                    <Col md={6}><InputField type="file" label="Robot" id="expertfile" text={robot.download_uri ?? "Upload robot exe file"} name="expert" required={robot_id === null} /></Col>
                    <Col><InputField type="file" label="Params" text="Upload robot ini file (optional)" name="settings" /></Col>
                </Row>
                <Row>
                    <Col md={3}><InputField type="number" label="Min.balance" name="min_balance" defaultValue={robot.min_balance} /></Col>
                    <Col md={3}><InputField type="number" label="Ref.server" name="ref_server_id" disabled defaultValue={robot.ref_server_id} /></Col>
                    <Col md={3}><InputField type="number" label="Ref.login" name="ref_login" disabled defaultValue={robot.ref_login} /></Col>
                    <Col><InputField type="password" label="Ref.password" name="ref_password" disabled defaultValue={robot.ref_password} /></Col>
                </Row>
                <Flags label="Enabled" value={flags} mask={0x1} onSwitch={handleEnabled} />
                <Row className="justify-content-center">
                    <Col md="auto">
                        <Button size="lg" disabled={changes.size === 0}><Lexema lexkey="submit">Submit</Lexema></Button>
                    </Col>
                </Row>
                <Alert isOpen={error !== null} color="danger" className="mt-3">{error}</Alert>
                <Input type="hidden" name="flags" defaultValue={flags} />
            </Form>
        </Container>
    )
};

export default withAuthenticationRequired(RobotEdit, {
    onRedirecting: () => <Loading />,
});
