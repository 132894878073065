import React from "react";
import Logo from "./Logo";
import Lexema from "./Lexema";
import { Button } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";

const Main = () => {
  const {
    loginWithRedirect,
  } = useAuth0();

  document.title = 'Intelligent Trading System';

  return (
    <div className="d-flex flex-column justify-content-between h-100 text-center main" >
      <div>
        <Logo className="main-logo my-5" />
        <h1 className="main-аh1">Are you ready to try our intelligent trading systems?</h1>
        <Button className="mt-5 col-4" color="primary" size="lg" onClick={loginWithRedirect}>Login</Button>
      </div>

      <div>
        <p className="align-text-bottom mb-5">
          <Lexema lexkey="subhero" def="Expert Advisor Perfect instrument for investing" />
        </p>
      </div>
    </div>
  );
}

export default Main;
