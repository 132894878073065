import React, { useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DropdownToggle, Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import { getConfig } from "../config";
import updateStorageStatus from "../utils/Storage";
import { USER_PERMISSIONS_ADMIN, UserRolesContext } from '../utils/UserRolesContext';


const Action = ({ initState, instance_id, ...args }) => {
    const [dropdownOpen, setdropdownOpen] = useState(false);
    const [state, setInstanceState] = useState(initState);
    const { apiOrigin } = getConfig();
    const { getAccessTokenSilently } = useAuth0();
    const { userRoles } = useContext(UserRolesContext);

    const onClickHandle = async (action) => {
        if (action === 'terminate' && !window.confirm('Confirm complete termination')) return;
        setInstanceState('wait');

        const token = await getAccessTokenSilently();
        const response = await fetch(
            `${apiOrigin}/api/instance/${instance_id}/${action}`,
            {
                method: 'post',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

        const jsonData = await response.json();
        if (jsonData.status === 'success') {
            setInstanceState(jsonData.result[0].CurrentState.Name);
            sessionStorage.removeItem('instances');
            sessionStorage.removeItem(instance_id);
        } else {
            setInstanceState('error');
        }
    }

    useEffect(() => {
        var nIntervId;

        const checkStatus = async () => {
            const token = await getAccessTokenSilently();
            const response = await fetch(`${apiOrigin}/api/instance/${instance_id}/status`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const jsonData = await response.json();
            if (jsonData.status === 'success' && ([16, 48, 80].indexOf(jsonData.data.InstanceState.Code) >= 0)) {
                //            clearInterval(nIntervId);
                updateStorageStatus(instance_id, jsonData.data.InstanceState.Name);
                setInstanceState(jsonData.data.InstanceState.Name);
            }
        }

        if (['running', 'stopped', 'terminated', 'error'].indexOf(state) < 0) {
            nIntervId = setInterval(checkStatus, 8000);
        }
        return () => {
            clearInterval(nIntervId);
        }
    }, [apiOrigin, getAccessTokenSilently, instance_id, state]);


    return (
        <Dropdown isOpen={dropdownOpen} toggle={() => { if (state !== 'terminated') setdropdownOpen(!dropdownOpen) }}>
            <DropdownToggle {...args} className={`robot-status robot-status-${state}`} >
                {state}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem disabled={state === 'running'} onClick={() => onClickHandle('start')}>Run</DropdownItem>
                {(userRoles.permissions & USER_PERMISSIONS_ADMIN) !== 0 && <DropdownItem disabled={state === 'stopped'} onClick={() => onClickHandle('stop')}>Stop</DropdownItem>}
                <DropdownItem divider />
                <DropdownItem onClick={() => onClickHandle('terminate')}>Terminate</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}

export default Action;