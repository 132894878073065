import React, { useState, useContext } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, NavItem } from "reactstrap";
import { UserRolesContext } from "../utils/UserRolesContext";

const LanguageSelector = () => {
  const { userRoles } = useContext(UserRolesContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectLanguage = (language) => {
    document.location.href = '?lang=' + language;
    setIsOpen(false);
  };

  return (
    <NavItem>
    <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
      <DropdownToggle className="nav-link nav-dropdown" caret>
        {userRoles.lang ?? 'en'}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem active={userRoles.lang === "en"} onClick={() => selectLanguage("en")}>
          English
        </DropdownItem>
        <DropdownItem active={userRoles.lang === "ru"} onClick={() => selectLanguage("ru")}>
          Russian
        </DropdownItem>
        <DropdownItem active={userRoles.lang === "th"} onClick={() => selectLanguage("th")}>
          Thai
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
    </NavItem>
  );
};

export default LanguageSelector;
