import { FormGroup, Input, Label } from "reactstrap";

const Flags = ({ value, mask, onSwitch, ...others }) => {

    function handleChange(e) {
        onSwitch(mask, e.target.checked);
    }

    const id = `flags-${mask}`;

    return (
        <FormGroup switch>
            <Input id={id} checked={(value & mask) !== 0} type="switch" role="switch" onChange={handleChange} {...others} />
            {others.label && (
                <Label check for={id}>{others.label}</Label>
            )}
        </FormGroup>
    );
}

export default Flags;
