import React, { useState, useContext, useEffect, useRef } from "react";
import { Router, Route, Switch, useLocation } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";

import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles mmd
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import Robot from "./components/Robot";
import Instances from "./views/Instances";

import { UserRolesContext, USER_PERMISSIONS_ADMIN } from './utils/UserRolesContext';
import Users from "./components/Users";
import { getConfig } from "./config";
import { getCookie } from "./utils/cookie";
import RobotEdit from "./components/RobotEdit";
import Partners from "./components/Partners";

initFontAwesome();

function translate(key, def, args = {}) {
  const ok = this.texts.has(key);
  if (!ok) return def;
  //
  const res = this.texts.get(key);
  if (!res) return def; else if (res.indexOf('${') < 0) return res;
  // eslint-disable-next-line
  const F = new Function('_', `return \`${res.replaceAll('${', '${_.')}\``);
  return F(args);
}

const Alert = ({ message }) => {
  return <div className="alert alert-danger" role="alert">Oops... {message}</div>;
}

const App = () => {
  const { isAuthenticated, isLoading, error, getAccessTokenSilently, user, logout } = useAuth0();
  const { userRoles, setUserRoles } = useContext(UserRolesContext);
  const [rolesLoaded, setRolesLoaded] = useState(false);
  const [rolesError, setRolesError] = useState("");

  // Использование useRef для хранения переменной partner
  const partnerRef = useRef();

  // Инициализация useRef значениями из параметров URL или cookies
  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    //
    partnerRef.current = searchParams.get('partner') || getCookie('partner');
    if (partnerRef.current && !getCookie('partner')) {
      document.cookie = `partner=${partnerRef.current};path=/;max-age=31536000;samesite=none;secure`;
    }
  }, []);

  useEffect(() => {
    if (isLoading) return;

    const searchParams = new URLSearchParams(document.location.search);
    //
    const { apiOrigin } = getConfig();
    var lang = searchParams.get('lang');
    if (lang) document.cookie = `lang=${lang};path=/;max-age=31536000;samesite=strict;secure`;

    // Функция для получения набора ролей пользователя
    const fetchUserRoles = async () => {
      const request = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        }
      }
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        request.headers.Authorization = `Bearer ${accessToken}`; // Передаем токен доступа          
        request.body = JSON.stringify(user);
        //
      }
      else {
        if (!lang) lang = getCookie('lang');
      }

      if (partnerRef.current) request.headers.partner = partnerRef.current;
      if (lang) request.headers.lang = lang;
      try {
        // Выполняем запрос к backend API, передавая Auth0 ID пользователя
        const response = await fetch(`${apiOrigin}/api/user/logon`, request);

        if (response.ok) {
          // Если запрос успешен, получаем набор ролей пользователя
          const roles = await response.json();

          console.log(roles)

          if (roles.status === 'success') {

            if (roles.redirect) {
              document.location.href = 'https://' + roles.redirect;
              return;
            }

            if (roles) {
              if (!roles.texts) roles.texts = {};
              roles.texts = new Map(Object.entries(roles.texts));
              roles.translate = translate.bind(roles);
            }

            // Обновим userRoles в контексте
            setUserRoles(roles);
            setRolesLoaded(true);
          } else {

            if (roles.code === 403)
              setRolesError('Access denied. Your account may have been disabled by the administrator or it has not been activated yet.');

            else
              setRolesError("Your account may have been disabled by the administrator or it has not been activated yet.");
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchUserRoles();
    return null;

  }, [isAuthenticated, isLoading, getAccessTokenSilently, setUserRoles, user]);

  useEffect(() => {
    let timeout;
    if (rolesError) {
      timeout = setTimeout(() => {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          }
        });
      }, 10000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [rolesError, logout]); // Зависимость от rolesError для перезапуска таймера при изменении ошибки

  if (error) {
    return <Alert message={error.message} />
  }

  if (rolesError) {
    return <Alert message={rolesError} />
  }

  if (isLoading || !rolesLoaded) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-3">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/robots/:robot_id" component={Robot} />
            <Route path="/instances/:instance_id" component={Instances} />
            <Route path="/instances" component={Instances} />
            {(userRoles.permissions & USER_PERMISSIONS_ADMIN) !== 0 ? [
              <Route key="1" path="/admin/users" component={Users} />,
              <Route key="2" path="/admin/robot/edit/:robotId" component={RobotEdit} />,
              <Route key="3" path="/admin/robot/create" component={RobotEdit} />,
              <Route key="4" path="/admin/partners" component={Partners} />
            ] : null}
            <Route path='*'><NoMatch /></Route>
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

function NoMatch() {
  let location = useLocation();
  document.title = 'No Match - 404'

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default App;
