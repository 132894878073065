const updateStorageStatus = (instanceId, status) => {
    const key = 'instances';
    const instances = JSON.parse(sessionStorage.getItem(key));
    if (instances) {
        for (let i = 0; i < instances.length; i++) {
            //console.log(instances[i].instance_id + ' ' + instanceId);
            if (instances[i].instance_id === instanceId) {
                if (instances[i].state.Name !== status) {
                    instances[i].state.Name = status;
                    sessionStorage.setItem(key, JSON.stringify(instances));
                    console.log('updated');
                }
                break;
            }
        }
    }
}

export default updateStorageStatus;