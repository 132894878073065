import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "./Loading";
import { getConfig } from "../config";
import Lexema from "./Lexema";
import { useAuth0 } from "@auth0/auth0-react";

/*
function randomInt(min, max) {
  min = Math.ceil(min); max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}
*/

const Content = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin } = getConfig();
  const [error, setError] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const getRobots = async () => {
      const token = await getAccessTokenSilently();
      try {
        const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json', }
        const response = await fetch(`${apiOrigin}/api/robots`, { headers: headers })

        if (!response.ok) {
          throw new Error('Error fetching robots list');
        }

        const data = await response.json();
        if (data.status === 'success') {
          setItems(data.data);
        } else {
          throw new Error(`error ${data.code}: ${data.message}`);
        }
      } catch (error) {
        console.error('Error fetching server list:', error);
        setError(error);
      }
      setLoaded(true);
    };

    getRobots();
  }, [apiOrigin, getAccessTokenSilently]);

  document.title = "Our Robots";
  if (error) {
    return <div>Ошибка: {error.message}</div>;
  } else if (!isLoaded) {
    return <Loading />
  } else {
    return (
      <div className="next-steps mt-5">
        <Row className="d-flex justify-content-start">
          {items.map((col, i) => (
            <Col key={col.id} md={4} className="mb-4">
              <div className="mx-auto robo-tile">
                <h6 className="mb-3 mx-auto">
                  <FontAwesomeIcon icon="link" className="mr-2" />
                  {col.name}
                </h6>
                <Link to={`/robots/${col.id}`}>
                  <img className={'mb-3 app-logo' + ((col.flags & 1) === 0 ? ' disabled' : '')} src={col.logo_uri} alt="" />
                </Link>
                <table className="robot-stat">
                  <tbody>
                    <tr><td><Lexema lexkey="pl" def="ProfitLoss" />:</td><td>{col.profit}%</td></tr>
                    <tr><td><Lexema lexkey="symbol" def="Symbol" />:</td><td>{col.symbol}.{col.period}</td></tr>
                    <tr><td><Lexema lexkey="type" def="Type" />:</td><td>{col.tag}</td></tr>
                    <tr><td><Lexema lexkey="stat_acc" def="Stat.Account" />:</td><td>{col.ref_login}</td></tr>
                  </tbody>
                </table>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  }

}

export default Content;
