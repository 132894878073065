import React, { useEffect, useState } from "react";
import { useParams, Link } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Col, Row } from "reactstrap";
import Loading from "../components/Loading";
import { getConfig } from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Action from "../components/Action";
import Statement from "../components/Statement";
import Title from "../components/Title";

function datefmt(date) {
    const iso = date.toISOString();
    return iso.slice(0, 10);//.concat(' ', iso.slice(11, 19));
}

/* function convertObjectKeysToLowerCase(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    const newObj = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            newObj[key.toLowerCase().replace(' ', '')] = obj[key];
        }
    }

    return newObj;
} */

const Instance = ({ details }) => {
    const created = new Date(details.created);
    const href = details.state.Code !== 48 ? `/instances/${details.instance_id}` : '#';
    return (
        <Col md={4} className="mb-4">
            <div className="mx-auto robo-tile">
                <h6 className="mb-3 mx-auto">
                    <Link to={href}>
                        <FontAwesomeIcon icon="link" className="mr-2 me-2" />
                        {details.tags.Expert}
                    </Link>
                </h6>
                <Link to={href}>
                    <img className="mb-3 app-logo" src={details.tags.ExpertLogo} alt="" />
                </Link>
                <table className="robot-stat">
                    <tbody>
                        <tr><td>Broker:</td><td>{details.tags.Broker}</td></tr>
                        <tr><td>Account:</td><td>{details.tags.Name}</td></tr>
                        <tr><td>Symbol:</td><td>{details.tags.Symbol}.{details.tags.Period}</td></tr>
                        <tr><td>Created:</td><td>{datefmt(created)}</td></tr>
                        <tr><td>State:</td><td><Action initState={details.state.Name} instance_id={details.instance_id} tag="span" className="robot-status" /></td></tr>
                    </tbody></table>
            </div>
        </Col>
    );
}

const InstancesList = ({ instances }) => {
    return (
        <>
            <Title title="My workers" subtitle="These guys are working on me" />
            <div className="next-step mt5">
                <Row className="d-flex justify-content-start">
                    {instances.map((instance) => (
                        <Instance key={instance.instance_id} details={instance} />
                    ))}
                </Row>
            </div>
        </>
    );
}

const Instances = () => {
    const [loaded, setLoaded] = useState(false);
    const [instances, setInstances] = useState([]);
    const { getAccessTokenSilently } = useAuth0();
    const { apiOrigin } = getConfig();

    const { instance_id = null } = useParams();
    const url = apiOrigin + '/api/instance' + (instance_id ? `/${instance_id}/statement` : ('s' + window.location.search));
    let lkey = instance_id ? instance_id : 'instances';

    useEffect(() => {
        const fetchDetails = async (hideLoadIndicator = false) => {
            if (!hideLoadIndicator) setLoaded(false);
            const token = await getAccessTokenSilently();
            try {
                const response = await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Error fetching instances');
                }

                const data = await response.json();

                if (data.status === 'success') {
                    if (data.data && (!instance_id || data.data.statement))
                        sessionStorage.setItem(lkey, JSON.stringify(data.data));
                    setInstances(data.data);
                } else {
                    throw new Error('Bad data while get instances');
                }
            } catch (error) {
                console.error('Error fetching instances:', error);
            }
            if (!hideLoadIndicator) setLoaded(true);
        };

        // пробуем кеш
        //const data = JSON.parse(sessionStorage.getItem(lkey));
        const data = null; // todo:

        if (data && !instance_id) {
            setInstances(data);
            setLoaded(true);
        }
        else {
            // в кеше чисто
            fetchDetails();
        }

        var nIntervId = setInterval(fetchDetails, 60 * 1000, true);
        return () => {
            clearInterval(nIntervId);
        }
    }, [lkey, url, instance_id, getAccessTokenSilently]);

    if (!loaded) return <Loading />

    document.title = instance_id ? `Statement ${instance_id}` : "Current Instances";

    if (instance_id) return <Statement statement={instances} />

    if (instances.length > 0) return <InstancesList instances={instances} />
    return <h2>Not found</h2>
}

export default withAuthenticationRequired(Instances, {
    onRedirecting: () => <Loading />,
});
