export function filterFormData(formData, allowedKeys) {
    const filteredFormData = new FormData();
  
    for (const [key, value] of formData.entries()) {
      if (allowedKeys.has(key)) {
        filteredFormData.append(key, value);
      }
    }
  
    return filteredFormData;
  }
