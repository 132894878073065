import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import Loading from "./Loading";
import { getConfig } from "../config";
import { ModalBody, ModalHeader, ModalFooter, Modal, Container, Row, Col, Button, Table, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import LaunchForm from "./LaunchForm";
import { Redirect } from 'react-router-dom';
import { USER_PERMISSIONS_ADMIN, USER_PERMISSIONS_INSTANCE, UserRolesContext } from '../utils/UserRolesContext';
import Lexema from "./Lexema";
import { getCookie } from "../utils/cookie";
import ServersByRobot from "./ServersByRobot";

const AdminAction = ({ robot_id, PermissionsVisible, onToggle = null }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin } = getConfig();
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  function handleDelete() {
    //
    toggleModal();

    const apiDelete = async () => {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}/api/robots/${robot_id}`, {
        method: 'delete',
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      });
      const result = await response.json();
      if (result.status === 'success') document.location.href = '/';
    }

    apiDelete();
  }

  return (
    <>
      <div>
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}><Lexema lexkey="confirm_delete">Confirm deletion</Lexema></ModalHeader>
          <ModalBody>
            <Lexema lexkey="confirm_delete_full">Confirm removal of the expert. This action is irreversible</Lexema>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              <Lexema lexkey="cancel">Cancel</Lexema>
            </Button>{' '}
            <Button color="danger" onClick={handleDelete}>
              <Lexema lexkey="delete">Delete</Lexema>
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <div className="container-fluid">
        <Dropdown size="sm" isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle className="admin-action" outline color="danger" caret><Lexema lexkey="admin">Admin</Lexema></DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => document.location.href = `/admin/robot/edit/${robot_id}`}><Lexema lexkey="edit">Edit</Lexema></DropdownItem>
            <DropdownItem onClick={toggleModal}>Delete</DropdownItem>
            <DropdownItem onClick={onToggle}>
              {PermissionsVisible ? (
                <Lexema lexkey="hide_permissions">Hide Permissions</Lexema>
              ) : (
                <Lexema lexkey="show_permissions">Show Permissions</Lexema>
              )}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </>
  );
}

const Robot = () => {

  const [modalOpen, setModalOpen] = useState(false);
  const [robot, setRobot] = useState([]);
  const [redirect, setRedirect] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [permissionsVisible, setPermissionsVisible] = useState(false);

  const { robot_id } = useParams();
  const { getAccessTokenSilently, user } = useAuth0();
  const { apiOrigin, adminEmail } = getConfig();

  const { userRoles } = useContext(UserRolesContext);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };


  const handleLogin = (selectedServer, symbol, login, password) => {

    async function LaunchInstance() {
      const data = { robot: robot.id, server: selectedServer, login: login, password: password };
      const symb = symbol.trim();
      if (symb !== '') data.symbol = symb;

      const token = await getAccessTokenSilently();

      const response = await fetch(`${apiOrigin}/api/instance`, {
        method: 'post',
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      });

      const result = await response.json();
      if (result.status === 'success') {
        console.log(`new instance ${result.instance} has launched`);
        sessionStorage.removeItem('instances');
        //
      } else {
        throw new Error(`reply status while launch instance: ${result.status} - ${result.message}`);
      }
      //
    }

    setModalOpen(false); // закрыть диалог
    setLoaded(false); // запустить spinner

    // ассинхронный вызов запуска нового инстанса
    LaunchInstance()
      .then(() => { setRedirect('/instances'); }) // редирект к списку инстансов при успехе
      .catch((err) => {
        setLoaded(true); // стоп спиннер
        alert(err);
      })
  };

  useEffect(() => {
    const fetchDetails = async () => {
      const token = await getAccessTokenSilently();
      const searchParams = new URLSearchParams(document.location.search);
      const lang = searchParams.get('lang');

      try {
        if (robot_id) {
          const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
          if (lang) headers.lang = lang;

          const response = await fetch(`${apiOrigin}/api/robots/${robot_id}`, {
            headers: headers,
          });

          if (!response.ok) {
            throw new Error('Error fetching server list');
          }

          const data = await response.json();
          if (data.status === 'success') {
            setRobot(data.data);
          } else {

            throw new Error(`error ${data.code}: ${data.message}`);
          }
        } else {
          setRobot([]);
        }
      } catch (error) {
        console.error('Error fetching server list:', error);
      }
      setLoaded(true);
    };

    fetchDetails();
  }, [apiOrigin, robot_id, getAccessTokenSilently]);

  if (redirect) return <Redirect to={redirect} /> // верхний уровень приоритета!
  if (!loaded) return <Loading />

  if (robot.length === 0) {
    document.title = `Robot ${robot_id} - not found`
    return (<h1>Not found</h1>);
  }

  document.title = `${robot.name}, ${robot.symbol}.${robot.period}`;

  return (
    <Container className="mb-5 robot-details">
      <Row className="align-items-center profile-header mb-5 text-md-left">
        <Col md={2} className="robo-logo">
          <img
            src={robot.logo_uri}
            alt=""
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
          {(userRoles.permissions & USER_PERMISSIONS_ADMIN) !== 0 &&
            <AdminAction robot_id={robot_id} PermissionsVisible={permissionsVisible} onToggle={() => setPermissionsVisible(!permissionsVisible)} />
          }
        </Col>
        <Col md>
          <h1><code>{robot.id}.</code> {robot.name}</h1>
          <Table className="table-sm _50x50">
            <tbody>
              <tr><td><Lexema lexkey="strategy" def="Strategy" />:</td><td>{robot.intro}</td></tr>
              <tr><td><Lexema lexkey="platform" def="Platform" />:</td><td>{robot.platform_name}</td></tr>
              <tr><td><Lexema lexkey="symbol" def="Symbol" />:</td><td>{robot.symbol}.{robot.period}</td></tr>
              <tr><td><Lexema lexkey="min_balance" def="Min.balance" />:</td><td>{robot.min_balance || <Lexema lexkey="any" def="any" />}</td></tr>
            </tbody>
          </Table>
          {(userRoles.permissions & USER_PERMISSIONS_ADMIN) !== 0 && (
            <Table className="table-sm _50x50">
              <tbody>
                {robot.meta.expert &&
                  <tr><td><Lexema lexkey="expert_fname" def="Expert file" />:</td><td>{robot.meta.expert}, {robot.meta.expert_size}b</td></tr>
                }
                {robot.meta.ini &&
                  <tr><td><Lexema lexkey="sets_fname" def="Settings file" />:</td><td>{robot.meta.ini}, {robot.meta.ini_size}b</td></tr>
                }
                {robot.meta.LastModified &&
                  <tr><td><Lexema lexkey="last_modified" def="Last modified at" />:</td><td>{robot.meta.LastModified}</td></tr>
                }
              </tbody>
            </Table>
          )}
          <div className="lead text-muted" dangerouslySetInnerHTML={{ __html: robot.description }} />
          {(userRoles.permissions & USER_PERMISSIONS_INSTANCE) === 0 || !robot.allow ? (
            <div>
              <Lexema lexkey={"weak_permissions" + userRoles.partner_id} from={user.email} mailto="admin@infoidea.com" forcehtml>
                <div>
                  To enable the ability to independently run this robot on your trading account, please send a request from <b>{user.email}</b> to the administrator's address at <a href={`mailto:${adminEmail}`}>{adminEmail}</a>
                </div>
              </Lexema>
            </div>
          ) : (
            <>
              <div className="text-center hero">
                <Button color="primary" size="lg" onClick={toggleModal}>
                  <Lexema lexkey="runit" def="Run on my account..." />
                </Button>
              </div>
              <LaunchForm robotId={robot_id} isOpen={modalOpen} toggle={toggleModal} onLogin={handleLogin} onSymbol={robot.symbol} />
            </>
          )}
          {permissionsVisible && (userRoles.permissions & USER_PERMISSIONS_ADMIN) !== 0 && (
            <>
              <h2 className="mb-3 mt-5">Robot Permissions</h2>
              <ServersByRobot robot_id={robot_id} />
            </>
          )}
        </Col>
      </Row>



    </Container>
  );
};

export default withAuthenticationRequired(Robot, {
  onRedirecting: () => <Loading />,
  ui_locales: getCookie('lang'),
});
