import React from "react";
import loading from "../assets/loading.svg";

const Loading = ({fullscreen = true}) => {
  document.title = "Loading...";
  return (
    <div className={fullscreen ? "spinner" : "spindiv"}>
      <img src={loading} alt="Loading" />
    </div>
  );
};

export default Loading;
