import { useContext } from "react";
import Lexema from "./Lexema";
import Logo from "./Logo";
import { UserRolesContext } from '../utils/UserRolesContext';

const Footer = () => {
  const { userRoles } = useContext(UserRolesContext);
  return (
    <footer className="bg-light p-3 text-center">
      <p className="fs-6"><Lexema lexkey="author" >Project provided by</Lexema> <code>{userRoles.partner ?? window.location.hostname}</code></p>
      <Logo />
    </footer>
  );
}

export default Footer;
