import { useEffect, useState } from "react";
import Loading from "./Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Table, Input } from "reactstrap";
import { getConfig } from "../config";

const { apiOrigin } = getConfig();


const ServerItem = ({ Row, RobotId }) => {
    const [allowed, setAllowed] = useState(Row.allow !== 0);
    const [updating, setUpdating] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    async function handleChange(e) {
        if (updating) {
            e.stopPropagation();
            return;
        }
        
        const root = document.getElementById('root');

        root.classList.toggle('waiting');
        setUpdating(true);
        try {
            const allowed = e.target.checked;
            const url = `api/robots/${e.target.dataset.robot}/server/${e.target.dataset.server}/${allowed}`;
            const token = await getAccessTokenSilently();
            const headers = {
                Authorization: `Bearer ${token}`, 'Content-Type': 'application/json',
            }

            const response = await fetch(`${apiOrigin}/${url}`, {
                method: 'post',
                headers: headers,
            });

            if (!response.ok) {
                throw new Error('Error fetching server list');
            }

            const data = await response.json();
            if (data.status !== 'success') {
                throw new Error(`error ${data.code}: ${data.message}`);

            } else {
                setAllowed(allowed);
            }

        } catch (error) {
            alert(error);
            //console.error('Error fetching server list:', error);
        }
        finally {
            setUpdating(false);
            root.classList.toggle('waiting');        
        }
    }

    return (
        <tr>
            <td>{Row.server_id}</td>
            <td>{Row.server}</td>
            <td>{Row.broker}</td>
            <td><div className="form-check form-switch">
                <Input checked={allowed} type="switch" role="switch" data-server={Row.server_id} data-robot={RobotId} onChange={handleChange} />
            </div></td>
        </tr>
    )
};

const ServersByRobot = ({ robot_id }) => {
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchDetails = async () => {
            const token = await getAccessTokenSilently();
            try {
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }

                const response = await fetch(`${apiOrigin}/api/robots/${robot_id}/byservers`, {
                    headers: headers,
                });

                if (!response.ok) {
                    throw new Error('Error fetching server list');
                }

                const data = await response.json();
                if (data.status === 'success') {
                    setItems(data.data);
                } else {
                    throw new Error(`error ${data.code}: ${data.message}`);
                }
            } catch (error) {
                console.error('Error fetching server list:', error);
            }
            setLoaded(true);
        };

        fetchDetails();
    }, [robot_id, getAccessTokenSilently]);

    if (!loaded) return null;//(<Loading />);
    return (
        <Table size="sm" hover>
            <thead>
                <tr>
                    <th>Server Id</th><th>Server</th><th>Broker</th><th>Allowed</th>
                </tr>
            </thead>
            <tbody>
                {items.map((row) => (
                    <ServerItem Row={row} RobotId={robot_id} key={row.server_id} />
                ))}
            </tbody>
        </Table>
    );
}

export default withAuthenticationRequired(ServersByRobot, {
    onRedirecting: () => <Loading />,
});