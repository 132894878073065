import Action from "./Action";
import { Table } from "reactstrap";
import Title from "./Title";
import { useEffect, useState } from "react";

function FormatMoney(value) {
    if (typeof value === 'number' && typeof value.toLocaleString === 'function') return value.toLocaleString('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return value;
}

const DATE_OPTIONS = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
};

const StatementItem = ({ Row }) => {
    return (
        <tr>
            {Row.map((data, index) => (
                <td key={index}>{data}</td>
            ))}
        </tr>
    );
}

const StatementItems = ({ List }) => {
    if (List)
        return (
            <Table className="statement-items">
                <thead>
                    <tr className="table-active">
                        {List.header.map((head, index) => (
                            <td key={index}>{head}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {List.items.map((row, index) => (
                        <StatementItem Row={row} key={index} />
                    ))}
                </tbody>
            </Table >
        );
    return (<p>There is no information yet</p>)
}

const Summary = ({ statement }) => {
    const summary = statement.statement;
    return (
        <Table className="statement-summary">
            <tbody>
                <tr><td>Statement date:</td><td className="statement-date">{new Intl.DateTimeFormat('en-US', DATE_OPTIONS).format(new Date(statement.ftime))}</td></tr>
                <tr><td>Balance:</td><td>{FormatMoney(summary.balance)}</td></tr>
                <tr><td>Equity:</td><td>{FormatMoney(summary.equity)}</td></tr>
                <tr><td>Margin:</td><td>{FormatMoney(summary.margin)}</td></tr>
                <tr><td>Credit:</td><td>{FormatMoney(summary.credit)}</td></tr>
                <tr><td>Float PL:</td><td>{FormatMoney(summary.floatPL)}</td></tr>
            </tbody>
        </Table>
    );
}

const Statement = ({ statement }) => {
    const [currState, setCurrState] = useState();
    const summary = statement.statement;
    const positions = summary ? summary.positions : undefined;
    const orders = summary ? summary.orders : undefined;

    useEffect(() => {
        const instances = JSON.parse(sessionStorage.getItem('instances'));
        if (!instances) return;
        const instance = instances.find(el => el.instance_id === statement.id);
        if (instance) {
            setCurrState(instance.state.Name);
        }
    }, [statement.id]);

    return (
        <>
            <Title title="Statement" subtitle={statement.id} />
            {summary ? (
                <>
                    <div className="statement next-step mt5">
                        <Summary statement={statement} />
                        <h2>Positions</h2>
                        <StatementItems List={positions} />
                        <h2>Orders</h2>
                        <StatementItems List={orders} />
                    </div >
                    {currState && (
                        <div className="text-center my-5">
                            <Action initState={currState} instance_id={statement.id} color="primary" size="lg" caret />
                        </div>
                    )}
                </>
            ) : (
                <p>There is no any information about worker trade yet</p>
            )}
        </>
    )
}
export default Statement;