import React, { Fragment } from "react";

import Hero from "../components/Hero";
import Content from "../components/Content";
import Main from "../components/Main";
import { useAuth0 } from "@auth0/auth0-react";

const Home = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <>
      {isAuthenticated ? (
        <>
          <Hero />
          <Content />
        </>
      ) : (
        <>
          <Main />
        </>
      )}
    </>
  );
}
export default Home;
